import React from "react";
import config from "../../../config";
import { Link } from "gatsby";
import Share from "../Share";

const Footer = () => {
  const isBrowser = typeof window !== `undefined`;
  const homePagePath =
    isBrowser &&
    (window.location.pathname === "/" ||
      window.location.pathname === "/contact" ||
      window.location.pathname === "/about")
      ? true
      : false;
  return (
    <footer className="footer has-background-black-ter has-text-white">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <h4 className="title has-text-white">About SIG</h4>
            <p style={{ maxWidth: "520px" }}>
              <strong className="has-text-white">
                Spectrum Insurance Group
              </strong>{" "}
              is an independent insurance and benefits planning/consulting firm.
              You can rely on us to offer&nbsp;comprehensive cost-effective yet
              flexible risk management programs.
            </p>
          </div>
          <div className="column">
            <h4 className="title has-text-white">Learn More</h4>
            <ul>
              <li>
                <Link className="hover-blue" to="/terms-of-use">
                  Terms of Use
                </Link>
              </li>
              <li>
                <Link className="hover-blue" to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <Link className="hover-blue" to="/contact">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link className="hover-blue" to="/privacy">
                  Privacy
                </Link>
              </li>
            </ul>
          </div>
          <div className="column">
            <h4 className="title has-text-white">Contact Us</h4>
            <p>6925 Oakland Mills Rd, #304</p>
            <p>Columbia, Maryland 21045</p>
            <a className="hover-blue" href="tel:3018549882">
              (301) 854-9882
            </a>
          </div>
        </div>
        <div className="content has-text-centered" style={{ padding: "1em" }}>
          {homePagePath && (
            <Share title={"Spectrum Insurance Group"} slug={""} />
          )}
          <p>
            <span>
              Copyright © {config.siteTitle} {new Date().getFullYear()}.{" "}
              {config.copyright}
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
