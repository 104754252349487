import React from "react";
import { Link } from "gatsby";
import config from "../../../config";

const NavBar = ({ toggleNavbar, isActive }) => (
  <>
    <nav className="navbar is-fixed-top" aria-label="main navigation">
      <div className="navbar-brand">
        <Link
          to="/"
          className="navbar-item"
          style={{
            padding: "1em",
            marginLeft: "1em",
          }}
        >
          {/* <strong>{config.siteTitle}</strong> */}
          <img src={config.siteLogo} alt={config.siteTitle} />
        </Link>
        <button
          className={`button navbar-burger ${isActive ? "is-active" : ""}`}
          data-target="navMenu"
          onClick={toggleNavbar}
          style={{ height: "inherit" }}
        >
          <span />
          <span />
          <span />
        </button>
      </div>
      <div
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
        id="navMenu"
      >
        <div className="navbar-end" style={{ cursor: "pointer" }}>
          <Link className="navbar-item" to="/about">
            About
          </Link>
          <Link className="navbar-item" to="/what-we-do">
            What We Do
          </Link>
          <Link className="navbar-item" to="/hr360">
            HR360
          </Link>
          {/* <Link className="navbar-item" to="/blog">
            Blog
          </Link>
          <a
            className="navbar-item"
            href="https://www.ihcmarketplace.com/Agent/2582697?productType=STM&bundleGID=2"
            target="__blank"
          >
            Temp Health
          </a> */}
          <div
            className="navbar-item"
            style={{ marginRight: "1em", cursor: "pointer" }}
          >
            <div className="field is-grouped">
              <p className="control">
                <Link className="button is-primary is-outlined" to="/contact">
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </>
);

export default NavBar;
