module.exports = {
  siteTitle: "Spectrum Insurance Group", // Site title.
  siteTitleAlt:
    "Customized Employee Benefits For Virginia, Maryland, and DC | Spectrum Insurance Group", // Alternative site title for SEO.
  siteLogo: "/icons/icon-868x409.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.spectruminsurancegroup.net/", // Domain of your website without pathPrefix.
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription:
    "Benefits Done Better Service. Innovation. Integrity. Discover the SIG Advantage! We Offer Competitive, Comprehensive, Well Supported Benefit Plans SIG's solutions-oriented approach goes beyond traditional employee benefit programs. We help you protect a diverse workforce while leveraging st ...", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml",
  siteFBAppID: "", // FB Application ID for using app insights
  googleTagManagerID: "", // GTM tracking ID.
  userName: "Craig Fyfe",
  userTwitter: "",
  copyright: "All Rights Reserved.", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#209CEE", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff", // Used for setting manifest background color.
};
